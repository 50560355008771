<template>
    <main class="post individual">
        <section id="intro">
      <h1>Résumé</h1>

      <p class="note">
<a href="https://data.christopherbreimhurst.com/wp-content/uploads/2024/10/Chris-Breimhurst-Resume.pdf" target="_blank">
      Download PDF
      </a>
    </p>
    <p><small>Updated: <em>October 18, 2024</em></small></p>

      
    
    <ul>
        <li>Web Developer­</li>
        <li>Graphic Designer</li>
        <li>User Experience (UX) Designer</li>
    </ul>
</section>


 <section id="competencies">
     
     <h2>Core Competencies:</h2>
     
     <article>
         <ul class="adjusted">
             <li><strong>User-Centered Design:</strong> Commitment to understanding user behaviors and needs through thorough research and user testing, ensuring that designs are tailored to exceed user expectations.</li>
             <li><strong>Wireframing and Prototyping:</strong> Proficient in using Figma to create wireframes and interactive prototypes, enabling iterative design improvements.</li>
             <li><strong>Collaborative Team Player:</strong> Excellence in cross-functional collaboration, working closely with developers, product managers, and stakeholders to ensure alignment and successful project outcomes.</li>
             <li><strong>Design Systems:</strong> Experience in creating and maintaining design systems to ensure consistency and scalability across all product interfaces.</li>
             <li><strong>Responsive Design:</strong> Skilled in designing for various platforms, including web and mobile, to deliver seamless user experiences across devices.</li>
         </ul>
     </article>
     
 </section>
 <section id="experieince">
     
     <h2>Professional Experience:</h2>

    <article id="thirty-tech">
         
         <header>
             <h3>Thirty Tech</h3>
             <p>
                <strong>Designer</strong><br>
                <em>January 2025 – Present</em>
             </p>
         </header>
         
         <ul class="adjusted">
           
         </ul>

      </article>
     
     <article id="pennant-creatives">
         
         <header>
             <h3>Pennant Creatives</h3>
             <p>
                <strong>Founder, Web Developer & Designer</strong><br>
                <em>July 2013 – July 2024</em>
             </p>
         </header>
         
         <ul class="adjusted">
             <li><strong>Strategic Planning:</strong> Weekly review of agency's long-term goals and objectives. Strategized how daily activities contribute to these goals.</li>
             <li><strong>Client Communication:</strong> Communicated with clients to discuss project updates, gather feedback, and address any concerns or questions they may have.</li>
             <li><strong>Team Management:</strong> Checked in with the creative team to discuss project progress, assign tasks, and provide guidance and feedback.</li>
             <li><strong>Project Management:</strong> Monitored ongoing projects to ensure they were on track, within budget, and meeting client expectations.</li>
             <li><strong>Client Pitches & RFPs:</strong> Collaborated on presentations for client pitches and Requests for Proposals (RFPs), contributing technical insights and helping to showcase the company’s capabilities and value proposition.</li>
             <li><strong>Creative Direction:</strong> Provided creative direction and input on projects, helping to generate innovative ideas and solutions.</li>
             <li><strong>Financial Management:</strong> Reviewed financial statements, budgeting, and invoicing to ensure the agency's financial solvency.</li>
             <li><strong>Quality Control:</strong> Ensured that the quality of work produced was consistent and met or exceeded industry standards.</li>
             <li><strong>Web Development:</strong> Built all client sites on WordPress using the GeneratePress theme and GenerateBlocks theme extension.</li>
         </ul>

     </article>

     <article id="planned-giving">
         
         <header>
             <h3>PlannedGiving.com</h3>
             <p>
                <strong>Web Services</strong><br>
                <em>July 2014 – October 2024</em>
             </p>
         </header>
         
         <ul class="adjusted">
             <li><strong>Design and Template Creation:</strong> Replicated the design and user interface of the organization's website, including layout, color schemes and fonts, to create a customizable template for the dynamic integration of Planned Giving marketing content.</li>
             <li><strong>Content Management:</strong> Regularly updated and modified content based on customer requests received via email or phone, ensuring timely and accurate adjustments.</li>
             <li><strong>Brand Consistency:</strong> Ensured seamless user experience throughout all updates and improvements.</li>
             <li><strong>Feature Development:</strong> Added new features to the proprietary platform to improve both the developer experience and the product offering, enhancing usability, efficiency, and overall functionality. Refactored legacy styles and scripts when newer Web APIs became available. Platform was built in PHP and MySQL.</li>
         </ul>
         
     </article>

     <article id="additional">
         <h2>Additional Experience:</h2>
         <ul class="adjusted">
         <li><strong>Kimberton Whole Foods</strong> - <em>February 2009 - June 2013 (intermittently)</em><br>
            Assistant Store Coordinator - Downingtown location,<br>
            Perishables Supervisor - Warehouse location</li>
         <li><strong>Lancaster Farm Fresh Cooperative</strong> - <em>June 2011 - June 2012</em><br>
            Operational Coordinator for Community Supported Agriculture (CSA) Program</li>
            <li>
              <strong>Whole Foods Market</strong> – <em>June 2007 – February 2009</em><br>
              Grocery clerk with a focus on dairy; merchandise ordering and inventory; trainer -  Portland, Maine location
            </li>
            <li>
              <strong>Redners Warehouse Market</strong> – <em> August 2001 – April 2007</em><br>
              Customer service lead; cashier; grocery clerk (including dairy and frozen); grocery receiver; deli server; produce clerk and preparation; merchandise ordering and inventory management
            </li>
         <li><strong>Home Field Advantage, Ltd.</strong> - <em>January 1998 - August 2006 (intermittently)</em><br>
            Various roles, including design work, 3D modeling of houses, brochure design, web development, and field construction</li>
            </ul>
         
     </article>
 </section>
<section id="skills-training" >
    <h2>Skills & Training:</h2>
    
    <article id="education">
        <h3>Education:</h3>
        
        <ul class="adjusted">

          <li>
            <strong>Kutztown University of Pennsylvania</strong> - <em>May 2006</em><br>
              Bachelors of Science in General Studies, Art Concentration
          </li>
        </ul>
        
    </article>
    
    <article id="computer-skills">
        
        <h3>Computer Skills:</h3>
        <ul>
            <li>Microsoft Word, Excel, Powerpoint</li>
            <li>Adobe Photoshop, Lightroom, Illustrator, InDesign, Premiere</li>
            <li>Figma</li>
        </ul>
        
    </article>

    <article id="programing-lang">
        <h3>Programming Languages:</h3>
        <ul>
          <li>HTML</li>
          <li>CSS</li>
          <li>JS</li>
          <li>PHP</li>
          <li>MySQL</li>
        </ul>
    </article>
    <article id="programing-frame">
        <h3>Programming Frameworks:</h3>
        <ul>
          <li>WordPress</li>
          <li>Vue</li>
          <li>React</li>
        </ul>
    </article>
    
    <article id="volunteering">
        
        <h3>Volunteer Work:</h3>
        <ul>
            <li>Lancaster Recreation Commission U8 soccer coach for the 2023 Spring and Fall seasons and 2024 Spring season.</li>
            <li>Created and launched lanc.care during the March 2020 lockdown of the COVID pandemic: <a href="https://penncapital-star.com/labor/were-just-trying-to-fill-the-void-ease-the-pain-across-lancaster-pa-volunteers-come-together-amid-covid-19-shutdowns/" target="_blank">Story</a></li>
            <li>Lancaster City Community Garden team member that helped build and maintain a local garden: <a href="https://lancasteronline.com/news/refugees-hope-community-garden-helps-them-put-down-roots-here/article_7a01eaa4-b2d8-5c74-859f-a4608b212182.html" target="_blank">Story</a></li>
        </ul>
    </article>
</section>

<section id="work">
    
    
    <h2>Examples of Work</h2>
    
    <article id="web-dev">
        
        <h3>Web Design & Development:</h3>
        <ul>
            <li><a href="https://careerreadylancaster.com/" target="_blank">Career Ready Lancaster!</a></li>
            <li><a href="https://www.lancastercountywib.com/" target="_blank">Lancaster County Workforce Development Board</a></li>
            <li><a href="https://www.uwlanc.org" target="_blank">United Way of Lancaster County</a></li>
            <li><a href="https://oneunitedlancaster.com/" target="_blank">One United Lancaster</a></li>
            <li><a href="https://illuccixhcp.com/" target="_blank">Illuccix HCP</a> <small>(development only)</small></li>
            <li><a href="https://illuccix.com/" target="_blank">Illuccix Patient</a> <small>(development only)</small></li>
            <li><a href="https://horseinnlancaster.com/" target="_blank">Horse Inn</a></li>
            <li><a href="https://philthygood.com/" target="_blank">Philthy Good</a></li>
            <li><a href="https://columbiapamarkethouse.org/" target="_blank">Columbia Market House</a></li>
            <li><a href="https://www.loomisco.com/" target="_blank">The Loomis Company</a></li>
            <li><a href="https://first10lancaster.com/" target="_blank">First 10 Lancaster</a></li>
            <li><a href="https://lititzpride.org/" target="_blank">Lititz Chooses Love</a></li>
            <li><a href="https://lancastercountycase.com/" target="_blank">Lancaster County CASE</a></li>
            <li><a href="https://jessicasmucker.com/" target="_blank">Jessica Smucker Music</a></li>
            <li><a href="https://somervillemanning.com/" target="_blank">Somerville Manning Gallery</a></li>
            <li><a href="https://heyjinx.com/" target="_blank">HeyJinx! Design</a></li>
            <li><a href="https://lanc.care/" target="_blank">Lanc.Care</a></li>
        </ul>
    </article>
    <article id="branding">
        <h3>Logo and Branding:</h3>
        <ul>
            <li><a href="https://heyjinx.com/" target="_blank">HeyJinx! Design</a></li>
            <li><a href="https://jessicasmucker.com/" target="_blank">Jessica Smucker Music</a></li>
            <li><a href="https://philthygood.com/" target="_blank">Philthy Good</a></li>
            <li><a href="https://careerreadylancaster.com/" target="_blank">Career Ready Lancaster!</a></li>
            <li><a href="https://www.bowerydevelopmentco.com/" target="_blank">Bowery Development Co.</a></li>
            <li><a href="https://thealistair.rentpmi.com/" target="_blank">The Alistair</a> <small>(including naming of building)</small></li>
        </ul>
    </article>
</section>

<section id="references">
    
    <h2>References</h2>
    
    <article>
      <p><a href="/contact">Available Upon Request</a></p>
    </article>
    
</section>




    </main>
  </template>
  
  <script>
  
  export default {
    computed: {
      posts() {
        return this.$store.state.posts;
      },
      post() {
        return this.posts.find(el => el.slug === this.slug);
      }
    },
    filters: {
      dateformat: function(value){
        let date = new Date(value)
        console.log(date)
        return date
      }
    },
    data() {
      return {
        slug: this.$route.params.slug
      };
    },
    created() {
      this.$store.dispatch("getPosts");
    }
  };
  </script>
  
  <style scoped>

#intro {
	width: 100%;
}

section {
  width: 100%;
}

section strong, section h2 {
	font-weight: 900;
}

  main .note {
    margin-bottom: 0px;
    display: block;
  }

  main .note a {
    display: inline-block;
    text-decoration-style: wavy;
  text-decoration-thickness: 0.1rem;
  margin-bottom: 0;
  }

  main .note + p{
    margin-top: 0px;
  }

  main #work a {
	text-decoration-thickness: 0.15rem;
}


#experieince header {
  position: relative;
}
#experieince header:before {
  content: "";
  height: 100%;
  width: 6px;
  background: var(--highlight);
  top: 0;
  left: -30px;
  position: absolute;
}

section article h3 {
  margin-bottom: 4px;
}
section article h3 + p, section article h3 + ul {
  margin-top: 0px;
}

article{
  padding: 1px 0 1px 28px;
  border-left: solid #ddd;
  margin-left: 15px;
  margin-bottom: 43px;
}

header h3,
header p {
    margin-bottom: 0;
}




 ul.adjusted {
  list-style: none;
  padding: 0;
}

 ul.adjusted li {
  text-indent: -20px;
  padding-left: 20px;
}

 ul.adjusted li+li {
  margin-top: 13px;
}


@media screen and (max-width: 668px) {
main .note a {
	padding: 12px 31px;
	font-size: 1rem;
}



.pre-title {
  font-size: 1rem;
  margin-bottom: 0;
}

section ul {
	padding-left: 28px;
}

section article ul {
	padding-left: 15px;
}

section article ul li {
	margin-top: 5px;
}

}
  </style>